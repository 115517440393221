
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { Tabs } from '../../../utilities/Types/navigation.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import axios from 'axios';
import router from '@/router';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    await this.resetPaginationState();
    const response = await this.fetchBrands();
    this.total = response?.total;

    if (this.$route.query.brandId) {
      this.updateModalComponent('brand');
      this.updateModalType('large');
      this.updateModalData({ brandId: this.$route.query.brandId, mode: 'edit', tab: this.$route.query.tab ? this.$route.query.tab : '' });
      this.openModal();
    }
  },
  watch: {
    loadingBrands: {
      handler() {
        this.loading = this.loadingBrands;
      },
    },
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const response = await this.fetchBrands();
          this.total = response?.total;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const response = await this.fetchBrands();
        this.total = response?.total;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const response = await this.fetchBrands();
        this.total = response?.total;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const response = await this.fetchBrands();
        this.total = response?.total;
      },
    },
  },
  computed: {
    ...mapGetters(['brands', 'loadingBrands', 'user', 'access', 'permissions']),
    isLoading(): boolean {
      return this.loading || this.loadingBrands;
    },
    showSearch(): boolean {
      return true;
      // return this.permissions && this.permissions?.superAdminAccess;
    },
    columnDefinitions(): ColumnDefinition[] {
      if (this.permissions && this.permissions?.superAdminAccess) {
        return [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Brand',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'companyDesc',
            displayName: 'Company',
          },
          {
            type: 'image',
            columnName: 'logoUrl',
            displayName: 'Image',
            position: 'center',
          },
          {
            type: 'text',
            columnName: 'statusDesc',
            displayName: 'Status',
          },
          {
            type: 'stringArray',
            columnName: 'userList',
            displayName: 'Users',
          },
          {
            type: 'text',
            columnName: 'brandId',
            displayName: 'Brand ID',
          },
        ];
      }
      return [
        {
          type: 'text',
          columnName: 'id',
          displayName: 'ID',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'description',
          displayName: 'Brand',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'companyDesc',
          displayName: 'Company',
        },
        {
          type: 'image',
          columnName: 'logoUrl',
          displayName: 'Image',
          position: 'center',
        },
        {
          type: 'text',
          columnName: 'statusDesc',
          displayName: 'Status',
        },
        {
          type: 'stringArray',
          columnName: 'userList',
          displayName: 'Users',
        },
      ];
    },
    tableData(): unknown {
      return this.brands;
    },
    tabs(): Tabs {
      return this.access && this.access?.companiesNone
        ? [
            {
              title: 'BRANDS',
              route: '/companies/brands/view',
              activeRouteCheck: '/companies/brands/view',
            },
          ]
        : [
            {
              title: 'COMPANIES',
              route: '/companies/view',
              activeRouteCheck: '/companies/view',
            },
            {
              title: 'BRANDS',
              route: '/companies/brands/view',
              activeRouteCheck: '/companies/brands/view',
            },
          ];
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: 'active' },
        { description: 'Upcoming', value: 'upcoming' },
        { description: 'Expired', value: 'expired' },
        { description: 'Draft', value: 'draft' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchBrands',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'resetPaginationState',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageReset = true;
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateStatusFilter(filter: IDropdownOption) {
      this.resetPageValue();
      this.statusFilterValue = filter;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    create(): void {
      this.updateModalComponent('brand');
      this.updateModalType('large');
      this.openModal();
    },
    reports(): void {
      this.$router.push('/reports');
    },
    async export(): Promise<void> {
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      const URL = `${process.env.VUE_APP_URI}/exports/brands`;
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
      // try {
      // } catch (e) {
      //   this.buttonFeedback.exportSubmitted = false;
      //   this.buttonFeedback.exportLoading = false;
      //   this.$notify({
      //     text: "We couldn't process your export request, please try again.",
      //     type: 'error',
      //     duration: 3000,
      //   });
      // }
    },
    rowClicked(row: any): void {
      this.updateModalComponent('brand');
      this.updateModalType('large');
      this.updateModalData({ brandId: row?.brandId, mode: 'edit' });
      this.openModal();
      router.replace({ query: { brandId: row?.brandId } });
    },
  },
});
